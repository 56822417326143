.popular-categories {
    $b: &;
    padding: 24px 65px 24px;
    position: relative;

    @media #{$media-to-sm} {
        padding: 12px 0 12px;
    }

    &__title {
        font-size: 32px;
        line-height: 48px;
        margin: 0 0 32px;
        text-align: center;
        color: $color-text-headings;

        @media #{$media-xs} {
            margin: 12px 0 32px;
            font-size: 16px;
            line-height: 22px;
            text-align: left;
        }
    }

    &__wrapper {
        margin-top: 10px;
    }

    #{$b}__list {
        margin: 0;
        display: flex;
    }

    #{$b}__item {
        list-style-type: none;
        margin-bottom: 0;

        @media #{$media-to-sm} {
            max-width: 180px;
        }
    }

    #{$b}__content {
        list-style-type: none;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: 100%;
        max-width: 188px;
        height:180px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid $sherpa-blue-disabled;
        min-width: 16.66%;

        @media #{$media-to-sm} {
            width: 188px;
            max-height:76px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
    }

    &__img {
        width: 64px;
        height: 64px;
        object-fit: cover;
        margin-bottom: 16px;

        @media #{$media-to-sm} {
            width: auto;
            height: 24px;
            margin-bottom: 0;
        }
    }

    &__link {
        color: $color-text-headings;
        font-weight: $font-weight-bold;
        font-size: 20px;
        line-height: 34px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media #{$media-to-sm} {
            max-width: 116px;
            font-size: 14px;
            line-height: 22px;
        }

        @media #{$media-to-sm} {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .glider-track {
        align-items: flex-start;
        gap: 60px;

        @media #{$media-to-md} {
            gap: 30px;
        }

        @media #{$media-to-sm} {
            gap: 20px;
        }
    }

    .glider-slide {

    }

    .slider-btn {
        position: absolute;
        top: 134px;
        visibility: hidden;
        opacity: 1;
        width: 56px;
        height: 60px;
        background-color: $white;
        border: 1px solid $sherpa-blue;
        border-radius: 50%;

        &:hover {
            border: 1px solid $green-hover;

            svg{
                path{
                    stroke: $stroke-hover;
                }
            }
        }

        svg{
            path{
                stroke: $color-text-headings
            }
        }

        @media screen and (max-width: 991px) {
            display: none;
        }

        &--right {
            right: -15px;
        }

        &--left {
            left: -15px;
        }
    }
}
